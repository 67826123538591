.a5 {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.a6 {
  font-weight: bold;
  font-size: 3rem;
  color: rgb(0, 0, 0);
}
.a8 {
  /* width: 240px;
  height: 240px; */

  width: 100%;
  height: auto;
}
.a9 {
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 1.5rem;
}
.a9a {
  color: rgb(0, 0, 0);
}
.a10 {
  display: flex;
  gap: 10px;
}
.a11 {
  height: 30px;
  border-radius: 20px;
}
.a12 {
  /* padding: 10px;
  width: 120px;
  color: white;
  border-radius: 6px;
  background-color: #2c3131; */

  height: 50px;
  background-color: #000000f3;
  width: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  border: none; 
  transition: transform 0.1s ease;
}
.a12:active {
  transform: scale(0.95); 
  background-color: #e3651a; 
}
.a13 {
  margin-top: 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.a14 {
  /* margin-top: 50px; */
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.a7 {
  gap: 20px;
  display: flex;
  justify-content: center;
}
.a14 {
  display: flex;
  justify-content: center;
}
.a16 {
  display: flex;
  align-items: center;
}
@media (max-width: 769px) {
  .a5 {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 531px) {
  .a7 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
