.e3 {
  width: 100%;
  max-width: 200px;
}
.footerTitle {
  font-size: 1.5rem;
  font-weight: 600;
}
.e5 {
  height: 35px;
  background-color: rgb(0, 0, 0);
  border-radius: 50px;
  width: 35px;
}
.e4 {
  display: flex;
  gap: 15px;
}
.e6 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.e10 {
  display: flex;
  justify-content: start;
  gap: 80px;
}
.e12 {
  font-weight: 700;
  font-size: 1.1rem;
}
.e11 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.e13 {
  font-weight: 600;
  color: gray;
}
.e14 {
  padding: 20px;
  text-align: center;
}
.e1 {
  color: rgb(0, 0, 0);
}
.e9 {
  color: rgb(0, 0, 0);
}
.e12 {
  color: rgb(0, 0, 0) !important;
}
.e13 {
  color: rgba(0, 0, 0, 0.527) !important;
}
.e14 {
  color: rgb(0, 0, 0);
}
