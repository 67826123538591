.a {
  border: 2px solid rebeccapurple;
}
.titleText {
  font-size: 1.5rem;
  font-weight: 600;
}
.logo-image {
  width: 100%;
  max-width: 200px;
}
.a2 {
  color: rgb(0, 0, 0);
  font-weight: 500;
}
.a2:hover{
  color: #b5b5b5c9;
}
.a3 {
  background-color: #1b4add;
  color: rgb(0, 0, 0);
  padding: 8px;
  text-align: center;
}
.a4 {
  background-color: #000000f3 !important;  /* Dark grey background */
  color: white;  /* White text */
  border-radius: 6px;
  border: none !important;
  padding: 10px 15px;  /* Adjust padding to make the button more prominent */
  font-size: 16px;  /* Larger font size for better readability */
  font-weight: 600;  /* Bold font weight */
  transition: background-color 0.3s, transform 0.3s;  /* Smooth transitions for hover effects */
}

.a4:hover {
  background-color: #787878f3 !important;  /* Lighter grey on hover */
  transform: translateY(-2px);  /* Slight raise effect on hover */
  cursor: pointer;  /* Change cursor to pointer to indicate clickability */
}
