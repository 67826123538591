.f3 {
  color: #f37419;
}
.f4 {
  font-size: 3.8rem;
  font-weight: 500;
}
.f5 {
  font-size: 3.4rem;
  font-weight: 500;
}
.f7 {
  color: #000000;
}
@media (max-width: 1400px) {
  .f4 {
    font-size: 3.5rem;
    font-weight: 500;
  }
}
@media (max-width: 1200px) {
  .f4 {
    font-size: 3rem;
    font-weight: 500;
  }
  .f5 {
    font-size: 2.8rem;
    font-weight: 500;
  }
}
@media (max-width: 993px) {
  .f4 {
    font-size: 2rem;
    font-weight: 500;
  }
  .f5 {
    font-size: 2rem;
    font-weight: 500;
  }
  .f9 {
    margin-top: 0px !important;
    color: black;
  }
  .f7 {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
}
@media (max-width: 320px) {
  .f12 {
    padding: 0px !important;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.f11 {
  height: 50px;
  background-color: #f37419;
  width: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: white;
  font-size: 20px;
}
.f6 {
  color: white;
}
