.b1 {
  height: auto;
  padding: 60px;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.b3 {
  color: #ffffff !important;
  font-size: 1.8rem;
}
@media (max-width: 499px) {
  .b1 {
    padding: 20px;
  }
}
.b2 {
  font-weight: bold;
  font-size: 2.3rem;
  color: rgb(255, 255, 255);
}
.b4 {
  /* width: ; */
  /* word-break: break-all; */
  width: 60%;
  font-size: 1.3rem;
}
.b4a {
  /* width: ; */
  /* word-break: break-all; */
  width: 50%;
}
.b5 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .b4 {
    width: 100%;
  }
  .b4a {
    width: 100%;
  }
}
.b6 {
  display: flex;
  justify-content: center;
  gap: 60px;
}
.b8 {
  font-weight: bold;
  font-size: 2.3rem;
}
.b10 {
  background-color: #ffffff !important;
  color: rgb(0, 0, 0);
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 6px;
  padding: 10px;
  /* width: 120px; */
  transition: transform 0.1s ease;
}

.b10:active {
  transform: scale(0.95); 
  background-color: #949696; 
}
@media (max-width: 490px) {
  .b2 {
    font-weight: bold;
    font-size: 1.6rem;
  }
  .b8 {
    font-weight: bold;
    font-size: 1.6rem;
  }
}
@media (max-width: 387px) {
  .b6 {
    flex-direction: column;
    gap: 30px;
  }
}
