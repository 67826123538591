.g3 {
  font-size: 2.7rem;
  font-weight: 500;
  color: #000000;
}
.g5 {
  font-size: 1.5rem;
  font-weight: 600;
}
.g4 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.g6 {
  display: flex;
  justify-content: space-between;
}
.g5 {
  color: rgb(0, 0, 0);
}
.g8 {
  color: rgba(0, 0, 0, 0.527) !important;
}
@media (max-width: 768px) {
  .g5 {
    font-size: 1.1rem;
}
}
