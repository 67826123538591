.d1 {
  height: 411px;
  width: 361px;
}
.d2 {
  position: relative;
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
}
.c3 {
  color: rgb(0, 0, 0) !important;
}
.d1 {
  position: sticky;
  top: 0;
}
.d4 {
  display: flex;
  gap: 20px;
  width: 23%;
  border: 1px solid #3b3b3b;
  padding: 30px;
  border-radius: 10px;
  background-color: #3b3b3b;
  color:rgb(0, 0, 0);
}
.d4a {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.d5 {
  font-size: 1.8rem;
  font-weight: bold;
}
.d7 {
  font-size: 1.1rem;
    font-weight: 500;
    min-height: 66px;
    color: rgb(255, 255, 255);
}
.d8 {
  font-size: 0.9rem;
  color: rgb(255, 255, 255);
  font-weight: 400;
}
@media (max-width: 992px) {
  .d4 {
    width: 47% !important;
  }
  .d7 {
      min-height: unset;
  }
}
@media (max-width: 768px) {
  .d2 {
    flex-direction: column;
    align-items: center;
  }
  .d4 {
    width: 100% !important;
  }
  
}

