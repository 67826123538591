.c1 {
  font-weight: bold;
  font-size: 2.6rem;
  color: #000000f3;
}
.c2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c3 {
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 80%;
}
.c6 {
  width: 50%;
}
.c6a {
  /* height: 120px; */
}
.c7 {
  color: rgb(0, 0, 0) !important;
}

.c8 {
  font-size: 1.6rem;
  font-weight: 700;
  padding-top: 15px;
}
.c9 {
  /* width: 340px; */
  color: gray;
  font-size: 1.1rem;
  padding-bottom: 15px;
}
.c5 {
  display: flex;
  gap: 20px;
}
.c10 {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.c11 {
  display: flex;
  gap: 40px;
}
@media (max-width: 1208px) {
  .c3 {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .c11 {
    flex-direction: column;
  }
}
@media (max-width: 480px){
  .c5 {
    flex-direction: column;
  }
  .c6{
    width: unset;
  }
}
@media (max-width: 405px) {
  .c5 {
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    align-items: center;
  }
  .c1 {
    text-align: center;
  }
}
