@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100&family=Caveat&family=Dancing+Script&family=Edu+TAS+Beginner&family=Fasthand&family=Maven+Pro&family=Nunito+Sans:wght@200&family=Pacifico&family=Playball&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0);
  font-family: "avenir bold", sans-serif;
}

.max_content {
  max-width: 1440px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  /* background-color: rgba(255, 255, 255, 0.8); Add your preferred background color with opacity */
  backdrop-filter: blur(23px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.logo {
    width: 182px;
    height: 65px;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff; /* Example button color */
  color: #fff;
  cursor: pointer;
  outline: none;
}

.connectwalls {
  background-color: rgb(44, 49, 49);
  color: rgb(255, 255, 255);
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 20px;
}
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--top [role=row], .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row] {
    background: #000 !important;
    color: #fff;
}

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    color: #fff;
}
.css-levciy-MuiTablePagination-displayedRows {
    color: #fff;
}
.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    color: #fff;
}

/* Add additional styles as needed */

.Debt {
  border: 0.1px solid lightgrey;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
  display: flex;
  gap: 20px;
  padding: 16px;
  border-radius: 15px;
  margin-top: 30px;
}
.dept_img {
  width: 120px;
  height: 120px;
}
.Debt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.CHAD h3 {
  font-size: 2rem;
  line-height: 1.375;
  font-weight: 700;
}
.CHAD h3 span {
  font-weight: 400;
  margin: 0 0 0 30px;
}

.view_btn button {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0 0 0 20px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(158, 119, 237, 0.5);
  color: rgb(158, 119, 237);
  border-radius: 12px;
  text-transform: none;
  padding: 8px 20px;
}

.pr h4 {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 2.5;
  text-transform: uppercase;
}
.pr p {
  margin: 0px;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.375;
  display: flex;
  align-items: center;
}
.al_de {
  width: 70%;
}
.pr p span {
  margin: 4px 0px 0px 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.tabs-container {
  width: 100%;
}

.Debt_two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 25px 0;
  gap: 25px;
}
.Debt_two .tab_grid {
  border-radius: 10px;
  border: 0.1px solid lightgrey;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgb(242, 244, 247);
  padding: 15px 0;
}

.tab {
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-bottom: none;
  color: grey;
}

.tab.active-tab {
  color: #fff;
  font-weight: 700;
  /* font-size: 20px; */
}

.tab-content {
  padding: 8px 24px;
}

.tabs .tab {
  /* font-size: 20px; */
  font-weight: 700;
}

.usdt {
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(108, 115, 127);
  font-weight: 400;
}
.usdt .zero {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375;
  /* color: #fff; */
}

.bottom_usdt {
  margin: 32px 0 0 0;
  /* background-color: rgb(243, 244, 246); */
  border: 1px solid #fff;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 15px 0 30px 0;
}

.overall {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.375;
  padding: 0 24px;
}

.connect_wallet h6,
.connect_wallet p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.connect_wallet h6 {
  font-weight: 700;
}

.typebox {
  border: 0.1px solid lightgrey;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
}
.text_tab {
  width: 95%;
}
.text_tab input {
  border: 0;
  outline: 0;
  font-size: 28px;
  font-weight: 500;
  line-height: 24px;
  background-color: #000;
  color: #fff;
}
.tether img {
  width: 27px;
  height: 27px;
}

.tether {
  border: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75;
  text-transform: uppercase;
  border-radius: 24px;
  padding: 10px 16px;
  background-color: rgb(243, 244, 246);
  color: rgba(0, 0, 0, 0.87);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.text_tab::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
.connectwall {
  background-color: rgb(88, 117, 127);
  color: rgb(255, 255, 255);
  padding: 10px;
  border: none;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.connectwall:hover {
  background-color: rgb(88, 117, 127);
  color: #fff;
}

.tab.active-tabs {
  color: rgb(158, 119, 237);
  background-color: rgba(158, 119, 237, 0.186);
  /* border-radius: 8px; */
  font-weight: 700;
}
.tabsc {
  margin-right: 0;
  padding: 11px;
}
.tab_index {
  border: 1px solid rgb(242, 244, 247);
  border-radius: 8px;
}

.tabsc:hover {
  background-color: rgba(100, 116, 139, 0.12);
}

.arbitrum img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.arbitrum {
  padding: 4px;
  margin-top: 16px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 16px;
  background-color: rgb(217, 231, 249);
}

.Secondary {
  margin: 30px 0px 32px;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.375;
  color: rgb(108, 115, 127);
}

.pools {
  margin: 30px 0px 80px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.57;
  color: rgb(108, 115, 127);
}
.cardtabs .tabs {
  border-bottom: 0;
  padding-left: 40px;
}

.cardtabs {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.cardtabs .tabs {
  display: flex;
  margin-bottom: 20px;
}

.cardtabs .tab-content {
  padding: 0;
}

.cardtabs .active-tab {
  background-color: black;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.cardtabs .tab {
  padding: 0px 20px;
}

.comming {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
  color: rgb(17, 25, 39);
  border: 0.1px solid lightgrey;
  border-radius: 15px;
  padding: 32px;
  display: flex;
  justify-content: center;
  margin: 15px 0 25px 0;
}
.comming img {
  width: 60%;
  object-fit: cover;
}
.overwrite {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
  padding: 32px;
  border: 0.1px solid lightgrey;
  border-radius: 15px;
  height: 100%;
}

.investor {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.75;
  border: 1px solid rgb(158, 119, 237);
  border-radius: 12px;
  padding: 8px 20px;
  background-color: #000;
  color: rgb(158, 119, 237);
}
.points {
    font-size: 0.75rem;
    line-height: 2.5;
    color: rgb(158, 119, 237);
}

.overwrite h5 {
  margin: 0px;
  font-weight: 700;
  font-size: 1.45rem;
  line-height: 1.2;
}

.overwrite p {
  margin: 8px 0px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57;
}
.tables {
  width: 100%;
}
.MuiDataGrid-columnHeaderCheckbox,
.MuiDataGrid-cell--textCenter {
  display: none !important;
}
.css-n3fyjk-MuiDataGrid-root {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px !important;
  border: 0.1px solid lightgrey !important;
  border-radius: 15px !important;
}
.MuiDataGrid-filler {
  border-radius: 0 15px 0 0 !important;
}
.footersection {
  padding-top: 150px;
}
.twologo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.xlogo {
  width: 30px;
  height: 30px;
}
.footersection a {
  text-decoration: none;
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;
  color: rgb(108, 115, 127);
}
.footers {
  /* border-bottom: 1px solid red; */
  border-bottom: 1px solid rgb(242, 244, 247);
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.footersection h6 {
  margin: 0px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.57;
}

.footersection h3 {
  font-weight: 400;
  line-height: 1.5;
  color: rgb(108, 115, 127);
  font-size: 16px;
}
.footersection p {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;
  color: rgb(108, 115, 127);
}
.copyright h2 {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57;
  color: rgb(108, 115, 127);
  padding-bottom: 50px;
}

.connectwalls:hover {
    background-color:rgb(44, 49, 49);
    color: #fff;
}
.footersection a .fa-minus{
  color: rgb(158, 119, 237);
}
.css-rtrcn9-MuiTablePagination-root:last-child{
  color: #FFF;
}
.css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel, .css-levciy-MuiTablePagination-displayedRows{
  margin: 0;
}
.MuiDataGrid-filler, .MuiDataGrid-scrollbarFiller, .MuiDataGrid-cellEmpty{
  display: none;
}
div[role="presentation"][style="width: 0px;"] {
  display: none;
}
.MuiDataGrid-columnHeaderTitle:hover{
  color:#FFF
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow, .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-row{
  justify-content: space-around;
}
.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled{
  color:#FFF !important;
}
.css-t89xny-MuiDataGrid-columnHeaderTitle {
  overflow: visible !important;
}
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-iconButtonContainer {
  visibility: hidden !important;
}
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator, .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator--resizing {
    visibility: hidden !important;
}
.css-ptiqhd-MuiSvgIcon-root {
  display: none !important;
}
.css-3eek4p-MuiDataGrid-main {
  border-radius: 15px;
}
/* .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel {
    color: #fff;
} */

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .al_de {
        width: 100%;
    }
    .dept_img {
        width: 120px;
        height: 105px;
    }

    .max_content {
        padding-left: 24px;
        padding-right: 24px;
    }
    .pr{
        width: 82%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .al_de {
        width: 100%;
    }
    .dept_img {
        width: 120px;
        height: 105px;
    }

    .max_content {
        padding-left: 24px;
        padding-right: 24px;
    }
    .pr{
        width: 90%;
    }
    .CHAD h3 {
    font-size: 20px;
}
}

@media screen and (max-width: 991px) {
    .CHAD{
        flex-direction: column;
    }
    .al_de {
        width: 100%;
    }
    .views{
        margin-left: 0 !important;
    }
    .view_btn{
        margin: 10px 0 5px 0;
        display: flex;
        align-items: center;
        gap: 16px;
    }
    .pr{
        display: grid !important;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
    .Debt_two {
        grid-template-columns: repeat(1, 1fr);
    }
    .bottom_usdt {
        margin-bottom: 150px;
    }
    .footersection {
        padding-top: 100px;
    }
    .footsec{
        padding-top: 25px;
    }
    .points {
      display: none;
    }
}


@media screen and (max-width: 767px) {
    .dept_img {
        display: none;
    }
    .CHAD h3 {
        font-size: 30px;
    }
    .cardtabs {
        align-items: center;
    }
    .footersection {
        padding-top: 40px;
    }
}
@media screen and (max-width: 575px) {

}

@media screen and (max-width: 480px) {
    .CHAD h3 {
        font-size: 24px;
    }
    .view_btn .addto{
      margin-left: 0;
    }
    .pr h4 {
    font-size: 10px;
}
.usdt {
    font-size: 12px;
}
.usdt .zero {
    font-size: 15px;
}
.view_btn button {
    font-size: 12px;
}
.tab {
    font-size: 12px;
}
.typebox h6{
  font-size: 14px;
}
.tether {
    font-size: 12px;
}
}
